<template>
  <div
    class="shop-height shop-page"
    :class="{
      'guest-user': isGuest,
      'brand-user': isBrand,
      'retailer-user': isRetailer,
    }"
  >
    <div
      v-for="(groupedProduct, index) in products"
      :key="index"
    >
      <div
        v-if="groupedProduct._id && groupedProduct.products.length"
        class="group-heading"
        :class="{'pt-2': !!wishlists.length}"
        :style="`z-index :${index + 2}`"
      >
        <div class="name">
          {{ groupedProduct._id }}
        </div>
      </div>
      <section
        ref="cardColumns"
        :class="itemViewClass"
        class="card-columns shop-items"
      >
        <b-card
          v-for="(product, key) in groupedProduct.products"
          :key="`${product._id}-${key}`"
          class="ecommerce-card ecom"
          :class="!isBrand ? 'ecom-card-padding' : ''"
          no-body
        >
          <span
            v-if="product.keyStyles"
            class="text-capitalize table-status key-style"
          >
            {{ product.keyStyles }}
          </span>
          <b-form-checkbox
            v-if="!isCheckBoxDisabled &&
              isShowWsp &&
              ((isRetailer && !inactiveCollection) || isBrand) &&
              !isBrandNotAccessible
            "
            :id="product._id"
            v-model="indexes"
            class="customCheck"
            :class="showCheckBox ? 'showCheckAll' : ''"
            :value="product._id"
            @change="addToArray(product, index)"
          />
          <span
            v-if="PRODUCT_STATUS.CANCELLED.VALUE === product.status"
            class="product-status-tag cancelled"
          >{{ PRODUCT_STATUS.CANCELLED.LABEL }}</span>
          <div class="item-img text-center p-0 pb-0 position-realtive">
            <b-link
              :class="showCheckBox ? 'pointerevents' : ''"
              target="_blank"
              @click="openProductDetail(product)"
            >
              <div class="card-square-image d-block">
                <b-card-img-lazy
                  class="bg-gray img img-responsive fullwidth"
                  :alt="`${product.name}-${product._id}`"
                  fluid-grow
                  :src="useEcommerceUi().parseProductImage(product)"
                  :class="`img-${product._id}`"
                  @load.native="calculateImageRatio(`img-${product._id}`)"
                  @error.native="imageLoadError"
                />
                <div class="product-color-wrapper">
                  <div
                    v-for="productColors in product.colors"
                    :key="productColors.color"
                    class="product-color-bg"
                  >
                    <div
                      v-if="getColorCode(productColors.colorFamily)"
                      v-b-tooltip.hover.left="productColors.color"
                      class="product-color"
                      :class="classForWhiteColor(productColors.colorFamily)"
                      :style="
                        'background:' + getColorCode(productColors.colorFamily)
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Product Details -->

              <b-card-body class="p-0">
                <div class="card-body-padding">
                  <div class="product-name item-name d-flex">
                    <b-link
                      :id="`tooltip_product_name_${product._id}`"
                      :class="showCheckBox ? 'pointerevents' : ''"
                      class="text-left"
                      @click="openProductDetail(product)"
                      @mouseover="
                        showTooltip(
                          `tooltip_product_name_${product._id}`,
                          product._id
                        )
                      "
                    >
                      {{ product.name }}
                    </b-link>
                    <b-tooltip
                      v-if="product._id === tooltipId"
                      class="font-Inter"
                      :target="`tooltip_product_name_${product._id}`"
                      placement="bottom"
                      triggers="hover"
                    >
                      {{ product.name }}
                    </b-tooltip>
                  </div>
                  <div class="style-code-margin">
                    <b-card-text class="product-style-code">
                      <span class="style-code"> Style Code: </span>
                      {{ product.styleCode }}
                    </b-card-text>
                  </div>
                  <div
                    class="d-flex flex-wrap"
                    :class="
                      isGuest
                        ? 'justify-content-end'
                        : 'justify-content-between'
                    "
                  >
                    <b-card-text
                      v-if="!isGuest && isShowWsp"
                      class="
                        text-left
                        product-price
                        text-nowrap
                        d-flex
                        flex-wrap
                      "
                    >
                      WSP:
                      <!-- To replace red strike line remove the condition -->
                      <span
                        v-if="!product.discountedPrice"
                        :class="{
                          'text-line-through': product.discountedPrice,
                        }"
                      >
                        {{ formatCurrency(product.price, product.currency) }}
                      </span>
                      <span
                        v-if="product.discountedPrice"
                        class="discounted-price"
                      >
                        {{
                          formatCurrency(
                            product.discountedPrice,
                            product.currency
                          )
                        }}
                      </span>
                    </b-card-text>
                    <p class="mb-0 product-price-rrp">
                      RRP:
                      {{
                        formatCurrency(product.retailPrice, product.currency)
                      }}
                    </p>
                  </div>
                </div>
              </b-card-body>
            </b-link>
          </div>
          <!-- Product Actions -->
          <div
            v-if="isRetailer || isGuest"
            :id="`tooltip_add_to_cart_btn_${product._id}`"
            class="item-options text-center shop-card-options"
          >
            <b-button
              v-if="isGuest"
              v-b-tooltip.hover.bottom.v-primary
              tag="a"
              class="btn-cart p-0 intro-add-cart h-auto button-cart"
              title="You should sign up to add the product in your cart."
              @click="onClickGuestSignUp"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
                size="18"
              />
              <span class="large-view-cart-text">Add to Cart</span>
            </b-button>
            <b-button
              v-if="isRetailer && isShowWsp"
              :class="showCheckBox ? 'pointerevents' : ''"
              variant="none"
              tag="a"
              class="btn-cart p-0 intro-add-cart button-cart"
              :disabled="inactiveCollection || isBrandNotAccessible"
              @click="reactHandleCartActionClick(product, key, index)"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
                size="18"
                :class="product.isInCart && 'cart-icon'"
              />
              <span class="compact-view-cart-text">{{
                product.isInCart ? 'In Cart' : 'Add'
              }}</span>
              <span class="large-view-cart-text">{{
                product.isInCart ? 'View In Cart' : 'Add to Cart'
              }}</span>
            </b-button>
            <TooltipAddToCartBtn
              v-if="isBrandNotAccessible && isShowWsp"
              :target-id="`tooltip_add_to_cart_btn_${product._id}`"
            />
            <b-button
              v-if="isRetailer && !isShowWsp"
              tag="a"
              :variant="connectionRequestStatus ? '' : 'primary'"
              class="btn-cart p-0 intro-add-cart button-cart"
              :disabled="!!connectionRequestStatus"
              @click="
                !connectionRequestStatus && $bvModal.show('connection_request')
              "
            >
              <span class="text-white">{{
                getRequestBtnText(connectionRequestStatus)
              }}</span>
            </b-button>
            <b-button
              v-if="isShowWsp"
              :class="showCheckBox ? 'pointerevents' : ''"
              variant="light"
              class="button-wishlist p-0 intro-add-wishlist"
              :disabled="isGuest || inactiveCollection || isBrandNotAccessible"
              @click="onToggleHeartIcon(product, key, index)"
            >
              <feather-icon
                icon="HeartIcon"
                size="18"
                :class="{ 'text-danger': product.isInWishlist }"
              />
            </b-button>
          </div>
        </b-card>
      </section>
    </div>
    <wishlist-list
      :wishlists="wishlists"
      :selected-wishlist="selectedWishlist"
      :is-disable-create-wishlist="isDisableCreateWishlistBtn"
      @move-to-wishlist="moveAllToWishList"
    />
    <b-modal
      id="brand-add-to-cart-modal"
      size="xl"
      hide-footer
      modal-class="brand-cart-modal"
      title="Add to Order"
      @close="onCloseAddToCartModal"
    >
      <brand-cart
        :cart-products="cartProducts"
        :retailer-id="retailerId"
        :is-loading-cart="isLoadingCart"
        :get-cart-products-by-brand="getCartProductsByBrand"
        :is-brand-cart-updated="isBrandCartUpdated"
        @close-brand-cart-modal="onCloseAddToCartModal"
      />
    </b-modal>
  </div>
</template>
<script>
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { isEllipsisActive } from '@/@core/utils/utils'
import constants, { DOWNLOAD_TYPES, FILE_FORMATS, wishlistAction } from '@/constants'
import { classForWhiteColor, getColorCode } from '@/product-colors'
import {
CLEAR_CART, FETCH_CART_PRODUCTS, UPDATE_SELECT_COUNT, UPDATE_IS_SELECTED_ALL_PRODUCTS, UPDATE_TOTAL_PRODUCTS_COUNT,
} from '@/store/modules/shop.module'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
BButton,
BCard,
BCardBody,
BCardImgLazy,
BCardText,
BFormCheckbox,
BLink,
BModal,
BTooltip,
VBModal,
VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import { DOWNLOAD_COLLECTION } from '@/store/modules/collection.module'
import { productDetailsRouteName, productDetailsWithWishlistRouteName } from '@/router/routes/apps'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import UserRoleMixin from '../UserRoleMixin.vue'
import BrandCart from '../checkout/BrandCart.vue'
import { useEcommerceUi } from '../useEcommerce'
import WishlistList from '../wishlist/WishlistList.vue'
import TooltipAddToCartBtn from './TooltipAddToCartBtn.vue'

const { formatCurrency } = utils

const { PRODUCT_STATUS } = c

const { parseProductImage } = useEcommerceUi
const { TRACKS } = constants

export default {
  components: {
    BCard,
    BButton,
    BTooltip,
    BLink,
    BFormCheckbox,
    BCardText,
    BCardBody,
    BCardImgLazy,
    TooltipAddToCartBtn,
    WishlistList,
    BrandCart,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin, ProductImageMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    wishlists: {
      type: Array,
      default: () => [],
    },
    selectedWishlist: {
      type: Object,
      default: () => {},
    },
    inactiveCollection: {
      type: Boolean,
      default: false,
    },
    isShowWsp: {
      type: Boolean,
      default: false,
    },
    connectionRequestStatus: {
      type: String,
      default: '',
    },
    isBrandNotAccessible: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      type: String,
      default: '',
    },
    reactHandleCartActionClick: {
      type: Function,
      default: () => {},
    },
    itemViewClass: {
      type: String,
      default: '',
    },
    reactToggleProductInWishlist: {
      type: Function,
      default: () => {},
    },
    hasDirectRetailers: {
      type: Boolean,
      default: false,
    },
    addMultipleItemsCartActionClick: {
      type: Function,
      default: () => {},
    },
    isCheckBoxDisabled: {
      type: Boolean,
      default: false,
    },
    isDisableCreateWishlist: {
      type: Boolean,
      default: false,
    },
    directRetailerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatCurrency,
      parseProductImage,
      useEcommerceUi,
      getColorCode,
      classForWhiteColor,
      tooltipId: '',
      selected: null,
      indexes: [],
      groupIndexes: [],
      productList: [],
      showCheckBox: false,
      cartProducts: [],
      retailerId: null,
      PRODUCT_STATUS,
      isBrandCartUpdated: 1,
      isDisableCreateWishlistBtn: false,
      deselectedProducts: [],
      isLoadingCart: false,
    }
  },
  computed: {
    ...mapState({
      profileData: state => state.auth.profileData,
      isSelectedAllProducts: state => state.shop.isSelectedAllProducts,
      totalProductsCount: state => state.shop.totalProductsCount,
    }),
  },
  watch: {
    isDisableCreateWishlist(val) {
      this.isDisableCreateWishlistBtn = val
    },
    products(val) {
      if (this.isSelectedAllProducts) {
        this.indexes = []
        val.forEach(groupedProduct => {
          groupedProduct.products.forEach(product => {
          this.indexes.push(product._id)
          })
        })
      }
    },
  },
  created() {
    this.$root.$refs.shop = this
    if (this.isRetailer) {
      this.retailerId = this.profileData?._id
    }
  },
  emits: ['on-deselect-products', 'update-products-by-wishlist', 'on-move-all-to-wishlist'],
  methods: {
    openProductDetail(product) {
      let name = productDetailsRouteName
      const params = { id: product._id, collectionId: this.collectionId }
      const query = {}
      if (this.directRetailerId) {
        query.retailerId = this.directRetailerId
      }
      if (this.selectedWishlist?._id) {
        name = productDetailsWithWishlistRouteName
        params.wishlistId = this.selectedWishlist._id
      }
      const routeData = this.$router.resolve({
        name,
        params,
        query,
      })
      window.open(routeData.href, '_blank')
    },
    getRequestBtnText(requestStatus) {
      if (requestStatus) {
        return requestStatus === CONNECTION_REQUEST_STATUS.DECLINED ? 'Request declined' : 'Request sent'
      }
      return 'Request WS price'
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
    addToOrder() {
      this.cartProducts = this.productList
      this.unSelectAll()
      analytics.track(TRACKS.ACTIONS.BRAND_CLICKS_ON_ADD_TO_ORDER, {
        product_count: this.cartProducts.length,
        product_ids: this.cartProducts.map(product => product._id),
      })
      this.$bvModal.show('brand-add-to-cart-modal')
    },
    onCloseAddToCartModal() {
      this.cartProducts = []
      this.$bvModal.hide('brand-add-to-cart-modal')
    },
    // Used when using import cart by brand
    async getCartProductsByBrand(retailerId) {
      this.isLoadingCart = true
      try {
        const params = {}
        if (this.isBrand) {
          params.retailerId = retailerId
        }
        const res = await this.$store
          .dispatch(FETCH_CART_PRODUCTS, params)
        this.cartProducts = res.data.data.products
        this.isBrandCartUpdated += 1
        setTimeout(async () => {
          const payload = {
            retailerId,
          }
          await this.$store.dispatch(CLEAR_CART, { payload })
      }, 1000)
        this.isLoadingCart = false
      } catch (e) {
        apiToastWarning('Failed to import cart')
        this.isLoadingCart = false
      }
    },
    onClickGuestSignUp() {
      this.$root.$refs.guestSignup.openModal()
    },
    selectOrCreateWishlist() {
this.isDisableCreateWishlistBtn = false
          this.$bvModal.show('wishlist-list')
    },
    addToArray(product, groupIndex) {
      if (event.target.checked) {
        this.showCheckBox = true
        this.productList.push(product)
        const groupIndexes = {
          groupIndex,
          productId: product._id,
        }
        this.groupIndexes.push(groupIndexes)
        this.$store.commit(UPDATE_SELECT_COUNT, this.productList.length)
        if (this.isSelectedAllProducts) {
            this.$store.commit(UPDATE_TOTAL_PRODUCTS_COUNT, this.totalProductsCount + 1)
            this.deselectedProducts = this.deselectedProducts.filter(
              p => p.productId !== event.target.value,
            )
          }
      } else {
        this.productList = this.productList.filter(
          p => p._id !== event.target.value,
        )
        this.groupIndexes = this.groupIndexes.filter(
          group => group.productId !== event.target.value,
        )
        if (this.productList.length === 0) this.showCheckBox = false
        this.$store.commit(UPDATE_SELECT_COUNT, this.productList.length)
        if (this.isSelectedAllProducts) {
            this.$store.commit(UPDATE_TOTAL_PRODUCTS_COUNT, this.totalProductsCount - 1)
            this.deselectedProducts.push(product)
          }
      }
      this.$emit('on-deselect-products', this.deselectedProducts)
    },
    onToggleHeartIcon(product, key, index) {
      if (product.isInWishlist) {
        this.reactToggleProductInWishlist(product, key, index, wishlistAction.REMOVE_FROM_WISHLIST)
        return
      }
      if (this.wishlists.length) {
        this.reactToggleProductInWishlist(product, key, index)
      } else {
        this.productList.push(product)
        const groupIndexes = {
          groupIndex: index,
          productId: product._id,
        }
        this.groupIndexes.push(groupIndexes)
        this.selectOrCreateWishlist()
      }
    },
    moveAllToWishList(data = {}) {
      const wishlistName = data?.wishlistName || ''
      const isCreateWishlist = !!data?.isCreateWishlist
      let wishlistId = null
      let wishlistData = null
      if (!isCreateWishlist) {
        wishlistId = data?.wishlistId
        wishlistData = data?.wishlist
      }
      if (this.isSelectedAllProducts) {
        this.$emit('on-select-all-move-to-wishlist', { wishlistId, wishlistName, isCreateWishlist })
        this.productList = []
        this.indexes = []
        this.showCheckBox = false
        return
      }
      const action = this.isRetailer ? wishlistAction.ADD_TO_WISHLIST : wishlistAction.ADD_BY_BRAND
      if (this.productList.length) this.reactToggleProductInWishlist(this.productList, this.indexes, this.groupIndexes, action, wishlistId, wishlistName, wishlistData, isCreateWishlist)
      this.productList = []
      this.indexes = []
      this.showCheckBox = false
      if (this.isRetailer) {
        this.$emit('update-products-by-wishlist', { wishlistId })
      }
    },
    removeAllFromWishList() {
      const action = wishlistAction.REMOVE_FROM_WISHLIST
      this.reactToggleProductInWishlist(this.productList, this.indexes, this.groupIndexes, action)
      this.productList = []
      this.indexes = []
      this.showCheckBox = false
    },
    moveAllToCart() {
      this.addMultipleItemsCartActionClick(this.productList, this.indexes, this.groupIndexes)
      this.productList = []
      this.indexes = []
      this.showCheckBox = false
    },
    unSelectAll() {
      this.productList = []
      this.indexes = []
      this.showCheckBox = false
      this.$store.commit(UPDATE_SELECT_COUNT, 0)
    },
    selectAll(select) {
      this.$store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, select)
      this.deselectedProducts = []
      this.indexes = []
      this.productList = []
      this.groupIndexes = []
      if (select) {
        this.indexes = []
        this.products.forEach((groupedProduct, index) => {
          groupedProduct.products.forEach(product => {
          this.indexes.push(product._id)
          this.productList.push(product)
          const groupIndex = {
            groupIndex: index,
            productId: product._id,
          }
          this.groupIndexes.push(groupIndex)
          })
        })
        this.$store.commit(UPDATE_SELECT_COUNT, this.productList.length)
      } else {
        this.indexes = []
        this.productList = []
        this.groupIndexes = []
        this.$store.commit(UPDATE_SELECT_COUNT, 0)
      }
    },
    async downloadSelectedProducts() {
      try {
        const payload = {
          downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
          respectiveId: this.collectionId,
          fileType: FILE_FORMATS.XLSX,
          productIds: this.indexes,
        }
        const params = {
          isOrderSheetDownload: true,
        }
        const res = await this.$store.dispatch(DOWNLOAD_COLLECTION, { payload, params })
        apiToastSuccess(res.data.message)
        this.productList = []
        this.indexes = []
        this.showCheckBox = false
        this.$store.commit(UPDATE_SELECT_COUNT, 0)
        this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
      } catch (err) {
        apiToastWarning(err)
      }
    },
  },
}
</script>
